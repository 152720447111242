import React from 'react';
import { useAppDispatch } from 'store/hooks';
import { useLocation } from '@reach/router';
import { setVisibility } from 'src/store/features/nav/main/navMainSlice';
import {
  LabelComponentProps,
  MobileDesktopNavSharedProps,
  NavItem,
  SubNavContainerProps,
} from './types';
import {
  hasActiveDescentant,
  isActive,
} from './services';
import MenuItem from './MenuItem';


export type Props = MobileDesktopNavSharedProps & {
  labelComponent: React.FC<LabelComponentProps>,
  subNavContainerComponent: React.FC<SubNavContainerProps>,
  onItemClicked?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    clickedItem: NavItem,
  ) => void,
};


const NavBase:React.FC<Props> = ({
  navItems,
  mobileBreakpointName,
  labelComponent,
  subNavContainerComponent,
  onItemClicked = () => {},
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [
    menuItemIdWithActiveDropdown,
    setMenuItemIdWithActiveDropdown,
  ] = React.useState<string>();
  const t = React.useRef<NodeJS.Timeout>();

  const handleNavItemClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    clickedItem: NavItem,
  ) => {
    onItemClicked(e, clickedItem);
    const hasChildren = !!(clickedItem?.children?.length);
    const isCollapsed = clickedItem.id !== menuItemIdWithActiveDropdown;
    if (hasChildren) {
      setMenuItemIdWithActiveDropdown(isCollapsed ? clickedItem.id : undefined);
    } else {
      t.current = setTimeout(() => {
        dispatch(setVisibility(false));
      }, 230);
    }
  };

  return (
    <>
      {navItems.map((item) => (
        <MenuItem
          key={item.id}
          onClick={handleNavItemClick}
          subNavActive={menuItemIdWithActiveDropdown === item.id}
          isActive={isActive(item.href, location.pathname)}
          isDescendantActive={hasActiveDescentant(item, location.pathname)}
          activePathname={location.pathname}
          mobileBreakpointName={mobileBreakpointName}
          labelComponent={labelComponent}
          subNavContainerComponent={subNavContainerComponent}
          {...item}
        />
      ))}
    </>
  );
};
export default NavBase;

