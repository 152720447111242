import { mix } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { ClickElement, MenuItemOuter } from '../MenuItem';
import { LabelComponentProps } from '../types';

export type Props = LabelComponentProps;

const DesktopLabel:React.FC<Props> = ({
  hasChildren,
  text,
  isChild,
  // mobileBreakpointName,
}) => {
  return (
    <DesktopLabelOuter
      className={[
        isChild ? 'is-child' : 'is-not-child',
        hasChildren ? 'has-children' : 'has-no-children',
      ].join(' ')}
    >
      {isChild && <BackgroundElement />}
      <InnerTextWrap>
        {text}
        {hasChildren && (
          <ChildIndicator> +</ChildIndicator>
        )}
      </InnerTextWrap>
    </DesktopLabelOuter>
  );
};
export default DesktopLabel;

export const DesktopLabelOuter = styled.div`
  position: relative;
  padding: 10px;
  &:after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 10px;
    right: 10px;
    height: 3px;
    background-color: ${(p) => p.theme.palette.interactive};
    opacity: 0;
    transition: opacity 0.3s, color 0.3s;
  }
  ${MenuItemOuter}.not-child > ${ClickElement} & {
    box-sizing: border-box;
    border: solid transparent;
    border-width: 8px;
  }
  ${MenuItemOuter}.is-child > ${ClickElement} & {
    box-sizing: border-box;
    border: solid transparent;
    border-width: 0 8px;
  }
  ${MenuItemOuter}.has-children.not-child > ${ClickElement} & {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  ${MenuItemOuter}.no-children.not-child > ${ClickElement} & {
    border-radius: 8px;
  }
  ${MenuItemOuter}.not-child.has-children:hover & {
    border-color: ${(p) => p.theme.palette.primary};
    background-color: ${(p) => p.theme.palette.primary};
  }
  ${MenuItemOuter}.is-child:last-of-type & {
    border-width: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  ${MenuItemOuter}.is-child > ${ClickElement} &,
  ${MenuItemOuter}.inactive.not-child > ${ClickElement} & {
    &:hover {
      color: ${(p) => mix(0.5, p.theme.palette.interactive, 'white')};
    }
  }
  ${MenuItemOuter}.active.not-child > ${ClickElement} & {
    cursor: default;
    &:after {
      opacity: 1;
    }
  }
  ${MenuItemOuter}.active.is-child > ${ClickElement} & {
    color: ${(p) => p.theme.palette.interactive};
    cursor: default;
  }
`;
const ChildIndicator = styled.span`
  color: ${(p) => p.theme.palette.interactive};
`;

const BackgroundElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0;
  border-radius: 5px;
  transition: opacity 0.3s;
  ${MenuItemOuter}:hover > ${ClickElement} & {
    opacity: 0.2;
  }
`;
const InnerTextWrap = styled.div`
  position: relative;
  white-space: nowrap;
`;
