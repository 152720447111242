import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { BreakpointKey } from 'src/styles/theme';
import useToBreakpoint from 'src/hooks/useToBreakpoint';
import { LabelComponentProps, NavItem, SubNavContainerProps } from './types';
import { hasActiveDescentant, isActive as isActiveService } from './services';


interface Props extends NavItem {
  id: string,
  onClick: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    clickedItem: NavItem,
  ) => void,
  subNavActive?: boolean,
  isActive: boolean,
  isDescendantActive: boolean,
  activePathname: string,
  mobileBreakpointName?: BreakpointKey,
  labelComponent: React.FC<LabelComponentProps>,
  subNavContainerComponent: React.FC<SubNavContainerProps>,
}

const MenuItem:React.FC<Props> = (props) => {
  const {
    href,
    label,
    children,
    onClick,
    isChild = false,
    subNavActive = false,
    isActive,
    isDescendantActive,
    activePathname,
    mobileBreakpointName = 'tablet',
    labelComponent: Label,
    subNavContainerComponent: SubNavContainer,
  } = props;
  const isMobile = useToBreakpoint(mobileBreakpointName);
  const hasChildren = !!(children?.length);
  const handleNavClickParent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick(e, props);
  };
  // const ClickElement = (hasChildren ? ButtonStyled : LinkStyled) as AnyStyledComponent;

  const outerClasses = classnames({ subNavActive }, [
    isChild ? 'is-child' : 'not-child',
    isActive ? 'active' : 'inactive',
    hasChildren ? 'has-children' : 'no-children',
    isDescendantActive ? 'descendant-active' : 'descendant-inactive',
  ]);

  return (
    <MenuItemOuter className={outerClasses}>
      <ClickElement
        to={href}
        as={(hasChildren && isMobile) ? ButtonStyled : LinkStyled}
        onClick={handleNavClickParent}
        $mobileBreakpointName={mobileBreakpointName}
      >
        <Label
          hasChildren={hasChildren}
          isChild={isChild}
          text={label}
          mobileBreakpointName={mobileBreakpointName}
        />
      </ClickElement>

      {children?.length && (
        <SubNavContainer isActive={subNavActive}>
          {children.map((childItem) => {
            const handleNavClickChild = (
              e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            ): void => onClick(e, childItem);
            return (
              <MenuItem
                key={childItem.id}
                isChild
                onClick={handleNavClickChild}
                isDescendantActive={hasActiveDescentant(childItem, activePathname)}
                isActive={isActiveService(childItem.href, activePathname)}
                activePathname={activePathname}
                mobileBreakpointName={mobileBreakpointName}
                labelComponent={Label}
                subNavContainerComponent={SubNavContainer}
                {...childItem}
              />
            );
          })}
        </SubNavContainer>
      )}
    </MenuItemOuter>
  );
};
export default MenuItem;


export const MenuItemOuter = styled.div`
  flex: 1 1 auto;
`;
export const ClickElement = styled.div``;
const LinkStyled = styled(Link)`
  display: block;
  text-decoration: none;
`;
const ButtonStyled = styled.button.attrs(() => ({ type: 'button' }))`
  display: block;
  padding: 0;
  margin: 0;
  appearance: none;
  border: none;
  background-color: transparent;
  color: inherit;
  font-weight: inherit;
  /* cursor: pointer; */
`;
