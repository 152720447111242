import React from 'react';
import styled from 'styled-components';
import { BreakpointKey } from 'src/styles/theme';
import DesktopLabel from './DesktopLabel';
import NavBase from '../NavBase';
import { MobileDesktopNavSharedProps } from '../types';
import DesktopSubNavContainer from './DesktopSubNavContainer';

export type Props = MobileDesktopNavSharedProps;

const DesktopNav:React.FC<Props> = ({ navItems, mobileBreakpointName }) => {
  return (
    <DesktopNavOuter mobileBreakpointName={mobileBreakpointName}>
      <NavBase
        navItems={navItems}
        mobileBreakpointName={mobileBreakpointName}
        labelComponent={DesktopLabel}
        subNavContainerComponent={DesktopSubNavContainer}
      />
    </DesktopNavOuter>
  );
};
export default DesktopNav;

const DesktopNavOuter = styled.div<{ mobileBreakpointName: BreakpointKey }>`
  ${(p) => p.theme.breakpoints.down(p.mobileBreakpointName)} {
    display: none;
  } // to minimise Google CLS metric during js page load
  display: flex;
  flex: 1 0 auto;
`;
