import { BreakpointsBasic } from 'styled';
import useCssBreakpointIndexes from './useCssBreakpointIndexes';

/**
 * Returns true for any breakpoint SMALLER THAN, NOT INCLUDING queryBreakpointName
 */
const useToBreakpoint = (
  queryBreakpointName: keyof BreakpointsBasic,
): boolean => {
  const { current, queried } = useCssBreakpointIndexes(queryBreakpointName);
  return current < queried;
};

export default useToBreakpoint;
