import React from 'react';
import styled from 'styled-components';
import { MenuItemOuter } from '../MenuItem';
import { SubNavContainerProps } from '../types';
import { DesktopLabelOuter } from './DesktopLabel';

export type Props = SubNavContainerProps;

const DesktopSubNavContainer:React.FC<Props> = ({
  isActive,
  children,
}) => {
  return (
    <DesktopSubNavContainerOuter>
      {children}
    </DesktopSubNavContainerOuter>
  );
};
export default DesktopSubNavContainer;

const DesktopSubNavContainerOuter = styled.div`
  position: absolute;
  display: none;
  ${MenuItemOuter}:hover & {
    display: block;
  }
`;
