import { trimSlashes } from 'helpers';
import { NavItem } from './types';


const removeLastPathSegment = (path: string) => path.split('/').slice(0, -1).join('/');


export function hasActiveDescentant(
  navItem: NavItem,
  activePathname: string,
  firstRun = true, // remove false positive for parent
): boolean {
  const checkNode = (node: NavItem): boolean => {
    return node.href === removeLastPathSegment(activePathname);
  };
  if (!firstRun && checkNode(navItem)) return true;
  if (!navItem?.children?.length) return false;

  for (let i = 0; i < navItem.children.length; i += 1) {
    if (hasActiveDescentant(navItem.children[i], activePathname, false)) return true;
  }
  return false;
}


export function isActive(
  href: string,
  activePathname: string,
): boolean {
  return activePathname.includes(href);
}
